// recharts
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  LineChart,
  Line,
  Legend
} from 'recharts';
// helpers
import { randomColorsForGraphs as COLORS } from '@helpers/utility';

type LineChartSMPropsType<T extends {}> = {
  data: T[];
  yKey: keyof T;
};

const chartStyles = {
  fontSize: 9,
  lineHeight: 11,
  stroke: '',
  fontFamily: 'Inter',
  fontWeight: 500
};

const LineChartSM = <T extends {}>({ data, yKey }: LineChartSMPropsType<T>) => {
  return (
    <ResponsiveContainer height={296}>
      <LineChart
        data={data}
        margin={{
          top: 10,
          right: 10,
          left: 5
        }}
      >
        <CartesianGrid stroke={'gray'} strokeWidth={1} strokeDasharray="3 3" />
        <XAxis tickMargin={10} dataKey={yKey.toString()} {...chartStyles} />
        <YAxis {...chartStyles} />

        {data[0] &&
          Object.keys(data[0]).map(
            (entry, index) =>
              entry !== yKey && (
                <Line
                  type="monotone"
                  key={`cell-${index}`}
                  stroke={COLORS[index % COLORS.length]}
                  fill={COLORS[index % COLORS.length]}
                  strokeWidth={2}
                  dataKey={entry}
                  dot={<></>}
                />
              )
          )}

        <Tooltip />
        <Legend iconType="circle" wrapperStyle={{ padding: '2rem 0rem' }} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartSM;
