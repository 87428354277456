import { Dispatch, SetStateAction, useMemo } from 'react';
//components
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import ChartCard from '@components/ChartCard/ChartCard';
import DataCard from '@components/DataCard/DataCard';
import { DatePicker } from '@mui/x-date-pickers';
import PieChartSM from '@components/Charts/PieChart/PieChart';
import BarChartCustom from '@components/Charts/BarChart/BarChartCustom';
//interfaces
import { MRT_ColumnDef } from 'material-react-table';
import {
  ObjectAnalyticsCriteriaType,
  ObjectAnalyticsFilterOptionsType,
  ObjectAnalyticsFilterResponseDataType,
  ObjectAnalyticsFilterResponseType
} from '@interfaces/analytics/analytics';
import dayjs, { Dayjs } from 'dayjs';
//hooks
import { useTranslation } from 'react-i18next';

type MostVisitedSportCentersPropsType = {
  criteria: ObjectAnalyticsCriteriaType[];
  data: ObjectAnalyticsFilterResponseType | undefined;
  selected: ObjectAnalyticsFilterOptionsType;
  setSelected: Dispatch<SetStateAction<ObjectAnalyticsFilterOptionsType>>;
  startDate: Dayjs;
  setStartDate: Dispatch<SetStateAction<Dayjs>>;
  endDate: Dayjs;
  setEndDate: Dispatch<SetStateAction<Dayjs>>;
};

const MostVisitedSportCenters = ({
  criteria,
  data,
  selected,
  setSelected,
  startDate,
  setStartDate,
  endDate,
  setEndDate
}: MostVisitedSportCentersPropsType): JSX.Element => {
  const { t } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<ObjectAnalyticsFilterResponseDataType>[]>(
    () => [
      {
        accessorFn(originalRow) {
          if ('name' in originalRow) return originalRow.name;
          else if ('day' in originalRow) return originalRow.day;
        },
        header: t('title')
      },
      {
        header: t('superadmin_analytics.appointments_number'),
        accessorFn(originalRow) {
          if ('appointments_count' in originalRow) return originalRow.appointments_count;
          else if ('value' in originalRow) return originalRow.value;
        }
      }
    ],
    []
    //end
  );
  console.log({ startDate, endDate });

  return (
    <Box>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={4}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel>{t('criterion')}</InputLabel>
            <Select
              value={selected}
              label={t('criterion')}
              onChange={(ev: SelectChangeEvent) =>
                setSelected(ev.target.value as ObjectAnalyticsFilterOptionsType)
              }
            >
              {criteria.map(el => (
                <MenuItem key={el.value} value={el.value}>
                  {el.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <FormControl sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 1 }}>
            <DatePicker
              label={t('from')}
              value={startDate}
              onChange={newValue => {
                newValue && setStartDate(newValue);
              }}
              maxDate={endDate}
            />
            <Box mx={1} my={2}>
              {'-'}
            </Box>

            <DatePicker
              label={t('to')}
              value={endDate}
              onChange={newValue => {
                newValue && setEndDate(newValue);
              }}
              minDate={startDate}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          <ChartCard
            title={t(`superadmin_analytics.${selected}`)}
            count={data?.total ?? 0}
            subtitle={t(`superadmin_analytics.total_number`)}
          >
            {selected === 'most_visited_days' ? (
              <BarChartCustom
                data={(data?.data as { day: string }[]) ?? []}
                yKey={'day'}
                multicolor
              />
            ) : (
              <PieChartSM data={data?.data ?? []} dataKey={'value' as never} />
            )}
          </ChartCard>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <DataCard
            title={t(`superadmin_analytics.${selected}`)}
            columns={columns}
            data={data?.data ?? []}
            showHeader
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MostVisitedSportCenters;
