// components

import { ResponsiveContainer, PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
// styles
import '@styles/components/charts.scss';
// helpers
import { randomColorsForGraphs as COLORS } from '@helpers/utility';

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = (props: any) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, percent, payload } = props;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      key={payload.name}
      fontFamily="Inter"
      fontSize={12}
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

type PieChartProps<T> = {
  data: T[];
  dataKey: keyof T;
};

const PieChartSM = <T extends {}>({ data, dataKey }: PieChartProps<T>): JSX.Element => {
  return (
    <ResponsiveContainer width="100%" height={270}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          dataKey={dataKey.toString()}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip itemStyle={{ fontFamily: 'Inter' }} />
        <Legend iconType="circle" />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartSM;
