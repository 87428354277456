import { useMemo } from 'react';
// types
import { type MRT_ColumnDef } from 'material-react-table';
import { SportCenterMostActiveUsersType } from '@interfaces/analytics/analytics';

// hooks
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';

// components
import { Box, Grid, ListItem } from '@mui/material';
import AnalyticsCard from '@components/AnalyticsCard/AnalyticsCard';
import ChartCard from '@components/ChartCard/ChartCard';
import DataCard from '@components/DataCard/DataCard';
import AreaChartSM from '@components/Charts/AreaChart/AreaChart';

// atoms
import { popupAtom } from '@atoms/popupAtom';

// queries
import {
  useSportCenterAnalyticsCount,
  useSportCenterAppointmentsCountByHour,
  useSportCenterMostActiveUsers
} from '@api/queries/analytics/analytics';

const AdminAnalyticsPage = () => {
  const { t } = useTranslation();
  const params = useParams();
  const setPopup = useSetRecoilState(popupAtom);
  const sportCenterID: number = Number(params.sportCenterId);

  const onAnalyticsError = (err: Error) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };
  const { data: sportCenterAnalyticsCount } = useSportCenterAnalyticsCount(
    sportCenterID,
    onAnalyticsError
  ); // povezati  sa komponentama dole sva tri hooka
  const { data: sportCenterAppointmentsCountByHour } = useSportCenterAppointmentsCountByHour(
    onAnalyticsError,
    sportCenterID
  );
  const { data: sportCenterMostActiveUsers } = useSportCenterMostActiveUsers(
    onAnalyticsError,
    sportCenterID
  );

  const mostActiveUsersColumn = useMemo<MRT_ColumnDef<SportCenterMostActiveUsersType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('user')
      },
      {
        accessorKey: 'favorite_sport.name',
        header: t('admin_analytics.favorite_sport')
      },
      {
        accessorKey: 'appointments_count',
        header: t('admin_analytics.total_reservations')
      },
      {
        accessorKey: 'appointments_sum_price',
        header: t('admin_analytics.total_amount_spent'),
        Cell: ({ row }) => <ListItem>{row.original.appointments_sum_price} RSD</ListItem>
      }
    ],
    []
    //end
  );

  return (
    <Box px={3} py={2}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <AnalyticsCard
            header={t('admin_analytics.total_app_appointments')}
            content={`${sportCenterAnalyticsCount?.data.data.total_count}`}
            percent={'0'}
            footerText={t('superadmin_analytics.compared_to_last_month')}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={4}>
          <AnalyticsCard
            header={t('admin_analytics.total_card_payments')}
            content={`${sportCenterAnalyticsCount?.data.data.card_payed_appointments.total_percentage}%`}
            percent={
              sportCenterAnalyticsCount?.data.data.card_payed_appointments.last_month_diff_in_percentage.toString() ||
              ''
            }
            footerText={t('superadmin_analytics.compared_to_last_month')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <AnalyticsCard
            header={t('admin_analytics.total_cash_payments')}
            content={`${sportCenterAnalyticsCount?.data.data.cache_payed_appointments.total_percentage}%`}
            percent={`${sportCenterAnalyticsCount?.data.data.cache_payed_appointments.last_month_diff_in_percentage}`}
            footerText={t('superadmin_analytics.compared_to_last_month')}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} mt={1}>
        <Grid item xs={12}>
          <ChartCard
            title={t('admin_analytics.object_visitation')}
            count={sportCenterAppointmentsCountByHour?.data.data?.appointments_total_count || 0}
            subtitle={t('today')}
            next="link to page"
          >
            <Box sx={{ height: '70%', px: 5 }} display="flex" justifyContent={'center'}>
              <AreaChartSM
                data={
                  sportCenterAppointmentsCountByHour?.data.data?.appointments_count_by_hour || []
                }
                tooltipValueText={t('appointments_count').toString()}
              />
            </Box>
          </ChartCard>
        </Grid>
      </Grid>

      <Grid container spacing={3} mt={1}>
        <Grid item xs={12}>
          <DataCard
            showHeader
            title={t('admin_analytics.most_active_users')}
            data={sportCenterMostActiveUsers?.data.data || []}
            columns={mostActiveUsersColumn}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminAnalyticsPage;
