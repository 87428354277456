// components

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Cell
} from 'recharts';
// helpers
import { randomColorsForGraphs as COLORS } from '@helpers/utility';
import { Box } from '@mui/material';

type BarChartPropsType<T extends {}> = {
  data: T[];
  yKey: keyof T;
  stackId?: string;
  multicolor?: boolean;
};

const chartStyles = {
  fontSize: 11,
  lineHeight: 11,
  stroke: '',
  fontFamily: 'Inter',
  fontWeight: 500,
  tickLine: false,
  tick: { stroke: 'transparent' },
  axisLine: { stroke: 'transparent' }
};

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{ backgroundColor: '#fff', padding: '0.25rem 0.75rem', border: '1px solid #ccc' }}
      >
        <p className="label">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const BarChartCustom = <T extends {}>({
  data,
  yKey,
  stackId,
  multicolor
}: BarChartPropsType<T>) => {
  return (
    <ResponsiveContainer height={296}>
      <BarChart
        data={data}
        margin={{
          top: 10,
          right: 10,
          left: 5
        }}
      >
        <CartesianGrid stroke={'gray'} strokeWidth={1} strokeDasharray="3 3" />

        <XAxis dataKey={yKey.toString()} {...chartStyles} />
        <YAxis {...chartStyles} />

        {data[0] &&
          Object.keys(data[0]).map(
            (entry, index) =>
              entry !== yKey && (
                <Bar key={`cell-${index}`} dataKey={entry} stackId={stackId} barSize={15}>
                  {data.map((entry, i) => (
                    <Cell
                      key={`cell-${i}`}
                      fill={multicolor ? COLORS[i % COLORS.length] : COLORS[index % COLORS.length]}
                    />
                  ))}
                </Bar>
              )
          )}
        <Tooltip cursor={{ opacity: 0.5 }} content={<CustomTooltip />} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartCustom;
