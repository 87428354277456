//components
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Icon,
  Stack,
  Typography
} from '@mui/material';
// theme
import theme from '@src/theme';
//icons
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
//hooks
import { useTranslation } from 'react-i18next';

type AnalyticsCardProps = {
  header: string;
  content: string | number;
  percent: string;
  footerText: string;
  showMore?: Function;
  headerIcon?: JSX.Element;
};

const AnalyticsCard = ({
  header,
  content,
  percent,
  footerText,
  headerIcon,
  showMore
}: AnalyticsCardProps): JSX.Element => {
  const tempPercent = parseInt(percent);
  const tempColor = tempPercent >= 0 ? theme.palette.success.light : theme.palette.error.light;

  const { t } = useTranslation();

  return (
    <Card sx={{ py: headerIcon ? 2 : 'auto', overflowX: 'auto' }}>
      <CardHeader
        sx={{ display: headerIcon ? 'none' : 'flex' }}
        title={
          <>
            <Typography variant="body1">{header}</Typography>
          </>
        }
        action={showMore && <Button endIcon={<NavigateNextIcon />}>{t('view_all')}</Button>}
      />
      <CardContent sx={{ pt: 0 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          {headerIcon && (
            <Icon sx={{ background: theme.palette.primary.main, borderRadius: 1, p: 1 }}>
              {headerIcon}
            </Icon>
          )}
          <Typography variant="h4">{content}</Typography>
        </Stack>
      </CardContent>

      <CardActions>
        <Box display="flex" alignItems="center" flexDirection="row">
          <Box sx={{ color: tempColor, display: headerIcon ? 'none' : 'flex' }} mx={1.5}>
            {tempPercent >= 0 ? (
              <TrendingUpIcon sx={{ mr: 1 }} />
            ) : (
              <TrendingDownIcon sx={{ mr: 1 }} />
            )}
            <Typography>{percent}%</Typography>
          </Box>
          <Typography variant="body2" color="text.secondary">
            {footerText}
          </Typography>
        </Box>
      </CardActions>
    </Card>
  );
};

export default AnalyticsCard;
