import React, { useMemo } from 'react';
//components
import { Box, Grid, ListItemText, Avatar, ListItem, ListItemAvatar } from '@mui/material';
import AnalyticsCard from '@components/AnalyticsCard/AnalyticsCard';
import ChartCard from '@components/ChartCard/ChartCard';
import DataCard from '@components/DataCard/DataCard';
import { StyledBadge } from '@components/StyledBadge/StyledBadge';
import LineChartSM from '@components/Charts/LineChart/LineChart';
//interfaces
import { type MRT_ColumnDef } from 'material-react-table';
import { LatestUserType } from '@interfaces/analytics/analytics';
//hooks
import { useTranslation } from 'react-i18next';
//api
import {
  useLatestUsers,
  useLatestUsersAnalyticsCount,
  useUsersAnalyticsCount,
  useUsersByGenders
} from '@api/queries/analytics/analytics';
//recoil
import { useSetRecoilState } from 'recoil';
import { popupAtom } from '@atoms/popupAtom';
// helpers
import dayjs from 'dayjs';

const UserAnalyticsPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation();

  const setPopup = useSetRecoilState(popupAtom);

  const onError = (err: Error) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const { data: latestUsers } = useLatestUsers(onError);
  const { data: usersCount } = useUsersAnalyticsCount(onError);
  const { data: latestUsersCount } = useLatestUsersAnalyticsCount(onError, {
    datetime_from: dayjs().subtract(1, 'week').format('YYYY-MM-DD HH:mm'),
    datetime_to: dayjs().format('YYYY-MM-DD HH:mm')
  });
  const { data: usersByGenders } = useUsersByGenders(onError);

  const userColumns = useMemo<MRT_ColumnDef<LatestUserType>[]>(
    () => [
      {
        accessorKey: 'id',
        header: t('users'),
        Cell: ({ row }) => (
          <ListItem>
            <ListItemAvatar>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="standard"
              >
                <Avatar src={row.original.profile_photo?.src} />
              </StyledBadge>
            </ListItemAvatar>
            <ListItemText
              primary={row.original.name}
              secondary={row.original.created_at_diff_for_humans}
            />
          </ListItem>
        )
      }
    ],
    []
    //end
  );

  return (
    <Box px={3} py={2}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <AnalyticsCard
            header={t('superadmin_analytics.total_app_users')}
            content={usersCount?.users.total_count ?? '0'}
            percent={usersCount?.users.last_month_diff_in_percentage ?? '0'}
            footerText={t('superadmin_analytics.compared_to_last_month')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AnalyticsCard
            header={t('superadmin_analytics.total_superusers')}
            content={usersCount?.coaches.total_count ?? '0'}
            percent={usersCount?.coaches.last_month_diff_in_percentage ?? '0'}
            footerText={t('superadmin_analytics.compared_to_last_month')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} mt={1}>
        <Grid item xs={12} sm={12} md={8}>
          <ChartCard
            title={t('superadmin_analytics.new_users')}
            count={latestUsersCount?.latest_users ?? 0}
            subtitle={t('superadmin_analytics.appointments_number_this_month')}
          >
            <Box sx={{ height: '70%', px: 5 }} display="flex" justifyContent={'center'}>
              <LineChartSM
                data={latestUsersCount?.count_users_by_day_of_month ?? []}
                yKey={'day'}
              />
            </Box>
          </ChartCard>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <DataCard
            title={t('superadmin_analytics.new_users')}
            columns={userColumns}
            data={latestUsers?.data.data || []}
            removePadding={true}
            showHeader
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <AnalyticsCard
            header={t('superadmin_analytics.total_men')}
            content={usersByGenders?.males.total_count ?? '0'}
            percent={usersByGenders?.males.last_month_diff_in_percentage ?? '0'}
            footerText={t('superadmin_analytics.compared_to_last_month')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AnalyticsCard
            header={t('superadmin_analytics.total_women')}
            content={usersByGenders?.females.total_count ?? '0'}
            percent={usersByGenders?.females.last_month_diff_in_percentage ?? '0'}
            footerText={t('superadmin_analytics.compared_to_last_month')}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserAnalyticsPage;
