//components
import AnalyticsCard from '@components/AnalyticsCard/AnalyticsCard';
import DataCard from '@components/DataCard/DataCard';
//hooks
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
//mui
import { Box, Grid, Switch, Typography } from '@mui/material';
//materia-react-table
import { MRT_ColumnDef } from 'material-react-table';
//theme
import theme from '@src/theme';
//icons
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FinanceCard from '@components/FinanceCard/FinanceCard';
import { use } from 'i18next';

type TransactionType = {
  id: number;
  name: string;
  sort: string;
  type: string;
  date: string;
  amount: string;
  status: 'confirmed' | 'pending';
  confirmTransaction: boolean;
};

//mock data
const historyTransactions: TransactionType[] = [
  {
    id: 1,
    name: 'Boopro Tech',
    sort: 'Izlaz',
    type: 'Programiranje',
    date: '21.03.2023',
    amount: '112,148.00',
    status: 'confirmed',
    confirmTransaction: true
  },
  {
    id: 2,
    name: 'Balkan Bet',
    sort: 'Ulaz',
    type: 'Sponzorstvo',
    date: '21.03.2023',
    amount: '112,148.00',
    status: 'pending',
    confirmTransaction: false
  }
];

export const FinancesPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const [transactionData, setTransactionData] = useState<TransactionType[]>(historyTransactions);
  const handleStatusChange = useCallback(
    (row: TransactionType) => {
      const newData: TransactionType[] = transactionData.map(item => {
        if (item.id === row.id) {
          return {
            ...item,
            status:
              row.status === 'confirmed' ? (row.status = 'pending') : (row.status = 'confirmed'),
            confirmTransaction: !row.confirmTransaction
          };
        }
        return item;
      });
      setTransactionData(newData);
    },
    [transactionData]
  );

  const columnsAllTransactions = useMemo<MRT_ColumnDef<TransactionType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('name'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false,
        size: 100
      },
      {
        accessorKey: 'sort',
        header: t('input_output'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false,
        size: 100,
        Cell: ({ cell }) => (
          <Typography color={cell.getValue<string>() === 'Ulaz' ? 'green' : 'error'}>
            {cell.getValue<string>()}
          </Typography>
        )
      },
      {
        accessorKey: 'type',
        header: t('type'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false,
        size: 100
      },
      {
        accessorKey: 'date',
        header: t('date'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false,
        size: 100
      },
      {
        accessorKey: 'amount',
        header: t('amount'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false,
        size: 100,
        Cell: ({ cell }) => cell.getValue<string>() + ' RSD'
      },
      {
        accessorKey: 'status',
        header: t('status'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false,
        size: 100,
        Cell: ({ cell, row }) => {
          const rowType = row.original;

          return rowType.confirmTransaction ? (
            <CheckCircleOutlineIcon color="success" />
          ) : (
            <HighlightOffIcon color="error" />
          );
        }
      },
      {
        accessorKey: 'confirmTransaction',
        header: t('finances_overview.confirm_transaction'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false,
        size: 100,
        Cell: ({ cell, row }) => {
          const rowType = row.original;
          return (
            <Switch
              onChange={() => handleStatusChange(rowType)}
              checked={cell.getValue<boolean>()}
            />
          );
        }
      }
    ],
    []
  );
  const totalIncome = Math.random() * 1000000;
  return (
    <Box m={2}>
      <Grid container mb={3}>
        <Grid container spacing={3} mb={1}>
          <Grid item xs={12} sm={4}>
            <AnalyticsCard
              header={t('finances_overview.total_income')}
              content={totalIncome.toLocaleString() + ' RSD'}
              percent={`${(Math.random() * 10).toFixed(2)}`}
              footerText={t('superadmin_analytics.compared_to_last_month')}
              // actionText={t('look').toUpperCase()}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <FinanceCard
              title={t('finances_overview.income_analytics').toString()}
              data={{ earnings: totalIncome - totalIncome / 3, outOf: totalIncome }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} alignSelf={'end'}>
            <AnalyticsCard
              header={t('finances_overview.total_outcome')}
              content={(totalIncome - 200000).toLocaleString() + ' RSD'}
              // color={theme.palette.success.light}
              percent={`-${(Math.random() * 10).toFixed(2)}`}
              footerText={t('superadmin_analytics.compared_to_last_month')}
              // actionText={t('look').toUpperCase()}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <FinanceCard
              title={t('finances_overview.expense_analytics').toString()}
              data={[
                { name: 'Zaposleni', value: 4000000 },
                { name: 'Računi', value: 2000000 },
                { name: 'Ostalo', value: 2500000 }
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
      <DataCard
        data={transactionData}
        columns={columnsAllTransactions as MRT_ColumnDef<{}>[]}
        title={t('finances_overview.transactions_history')}
        showHeader={true}
        withAction
        actionText={t('view_all').toUpperCase()}
      />
    </Box>
  );
};
