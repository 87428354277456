//interfaces
import {
  AnalyticsCountsResponseType,
  AppointmentsCountByHourResponseType,
  CourtCountBySportResponseType,
  LatestAppointmentsResponseType,
  LatestUsersResponseType,
  SportCenterAppointmentsCountByHourResponseType,
  SportCenterMostActiveUsersResponseType,
  SportCenterAnalyticCountType,
  SportCenterAnalyticCountResponseType,
  ObjectAnalyticsCountsTypes,
  ObjectAppointmentsCountType,
  ObjectsMostPopularAnalyticsType,
  ObjectAnalyticsCriteriaType,
  ObjectAnalyticsFilterQueryParamsType,
  ObjectAnalyticsFilterResponseType,
  UsersCountType,
  LatestUsersCountType,
  UsersByGendersType
} from '@interfaces/analytics/analytics';
//services
import {
  getAnalyticsCounts,
  getAppointmentsCountByHour,
  getCourtsCountBySport,
  getLatestUsers,
  getLatestUsersCount,
  getLatestappointments,
  getObjectAnalyticsCounts,
  getObjectAppointmentsCount,
  getObjectFilterAnalytics,
  getObjectGraph,
  getObjectMostPopular,
  getSportCenterAnalyticsCount,
  getSportCenterAppointmentsCountByHour,
  getSportCenterMostActiveUsers,
  getUsersByGenders,
  getUsersCount
} from '@services/analytics/analytics';
//react-query
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export const useAnalyticsCounts = (
  onError: (err: Error) => void
): UseQueryResult<AnalyticsCountsResponseType, Error> =>
  useQuery(['superadminAnalyticsCounts'], () => getAnalyticsCounts(), {
    onError: err => onError(err)
  });

export const useLatestUsers = (
  onError: (err: Error) => void,
  limit?: number
): UseQueryResult<LatestUsersResponseType, Error> =>
  useQuery(['superadminLatestUsers', limit], () => getLatestUsers(limit), {
    onError: err => onError(err)
  });

export const useAppointmentsCountByHour = (
  onError: (err: Error) => void,
  datetime_from?: string,
  datetime_to?: string
): UseQueryResult<AppointmentsCountByHourResponseType, Error> =>
  useQuery(
    ['superadminAppointmentsCountByHour', datetime_from, datetime_to],
    () => getAppointmentsCountByHour(datetime_from, datetime_to),
    {
      onError: err => onError(err)
    }
  );

export const useLatestAppointments = (
  onError: (err: Error) => void,
  limit?: number
): UseQueryResult<LatestAppointmentsResponseType, Error> =>
  useQuery(['superadminLatestAppointments', limit], () => getLatestappointments(limit), {
    onError: err => onError(err)
  });

export const useCourtsCountBySports = (
  onError: (err: Error) => void
): UseQueryResult<CourtCountBySportResponseType, Error> =>
  useQuery(['superadminCourtsCountBySports'], () => getCourtsCountBySport(), {
    onError: err => onError(err)
  });

export const useSportCenterAnalyticsCount = (
  sportCenter: number,
  onError: (err: Error) => void
): UseQueryResult<SportCenterAnalyticCountResponseType, Error> =>
  useQuery(
    ['adminSportCenterAnalyticsCount', sportCenter],
    () => getSportCenterAnalyticsCount(sportCenter),
    {
      onError: err => onError(err)
    }
  );

export const useSportCenterAppointmentsCountByHour = (
  onError: (err: Error) => void,
  sportCenter: number,
  datetime_from?: string,
  datetime_to?: string
): UseQueryResult<SportCenterAppointmentsCountByHourResponseType, Error> =>
  useQuery(
    ['adminSportCenterAppointmentsCountByHour', sportCenter, datetime_from, datetime_to],
    () => getSportCenterAppointmentsCountByHour(sportCenter, datetime_from, datetime_to),
    {
      onError: err => onError(err)
    }
  );

export const useSportCenterMostActiveUsers = (
  onError: (err: Error) => void,
  sportCenter: number,
  limit?: number
): UseQueryResult<SportCenterMostActiveUsersResponseType, Error> =>
  useQuery(
    ['adminSportCenterMostActiveUsers', sportCenter, limit],
    () => getSportCenterMostActiveUsers(sportCenter, limit),
    {
      onError: err => onError(err)
    }
  );

export const useObjectAnalyticsCounts = (
  onError: (err: Error) => void
): UseQueryResult<ObjectAnalyticsCountsTypes, Error> =>
  useQuery(['superadminObjectAnalyticsCounts'], () => getObjectAnalyticsCounts(), {
    onError: err => onError(err),
    select: data => data.data.data
  });

export const useObjectAppointmentsCount = (
  onError: (err: Error) => void
): UseQueryResult<ObjectAppointmentsCountType, Error> =>
  useQuery(['superadminObjectAppointmentsCounts'], () => getObjectAppointmentsCount(), {
    onError: err => onError(err),
    select: data => data.data.data
  });
export const useObjectMostPopularCounts = (
  onError: (err: Error) => void
): UseQueryResult<ObjectsMostPopularAnalyticsType[], Error> =>
  useQuery(['superadminObjectMostPopularCounts'], () => getObjectMostPopular(), {
    onError: err => onError(err),
    select: data => data.data.data
  });
export const useObjectGraph = (
  onError: (err: Error) => void
): UseQueryResult<ObjectAnalyticsCriteriaType[], Error> =>
  useQuery(['superadminObjectGraphCriteria'], () => getObjectGraph(), {
    onError: err => onError(err),
    select: data => data.data.data
  });
export const useObjectFilterAnalytics = (
  params: ObjectAnalyticsFilterQueryParamsType,
  onError: (err: Error) => void
): UseQueryResult<ObjectAnalyticsFilterResponseType, Error> =>
  useQuery(['superadminObjectFilterAnalytics', params], () => getObjectFilterAnalytics(params), {
    onError: err => onError(err),
    select: data => data.data.data
  });

export const useUsersAnalyticsCount = (
  onError: (err: Error) => void
): UseQueryResult<UsersCountType, Error> =>
  useQuery(['superadminUsersAnalyticsCount'], () => getUsersCount(), {
    onError: err => onError(err),
    select: data => data.data.data
  });

export const useLatestUsersAnalyticsCount = (
  onError: (err: Error) => void,
  params?: {
    datetime_from: string;
    datetime_to: string;
  }
): UseQueryResult<LatestUsersCountType, Error> =>
  useQuery(['superadminLatestUsersAnalyticsCount', params], () => getLatestUsersCount(params), {
    onError: err => onError(err),
    select: data => data.data.data
  });

export const useUsersByGenders = (
  onError: (err: Error) => void
): UseQueryResult<UsersByGendersType, Error> =>
  useQuery(['superadminUsersByAnalyticsCount'], () => getUsersByGenders(), {
    onError: err => onError(err),
    select: data => data.data.data
  });
