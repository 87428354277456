// hooks
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

// components
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import AnalyticsCard from '@components/AnalyticsCard/AnalyticsCard';
import AreaChartSM from '@components/Charts/AreaChart/AreaChart';
import FinanceCardNavigation from '@components/FinanceCard/FinanceCardNavigation';
import BarChartSM, { BarData } from '@components/Charts/BarChart/BarChart-UNUSED';

// containers
import CardWrapper from '@containers/CardWrapper/CardWrapper';
import TableHeaderActions from '@containers/TableHeaderAction/TableHeaderActions';
import { TableLayout } from '@layout/TableLayout/TableLayout';

// dayjs
import dayjs from 'dayjs';

// icons
import { ClockIcon, HouseIcon, MobileIcon } from '@components/icons/icons';
import PieChartSM from '@components/Charts/PieChart/PieChart';
import { ArrowRightIcon, DateView } from '@mui/x-date-pickers';
import { ba } from '@fullcalendar/core/internal-common';
import BarChartCustom from '@components/Charts/BarChart/BarChartCustom';

const AdminFinancePage = () => {
  const { t } = useTranslation();
  const [date, setDate] = useState<string>(dayjs().format());
  const [barDate, setBarDate] = useState<string>(dayjs().format());
  let endDate = +dayjs(barDate).endOf('month').format('DD');
  const [viewTypeArea, setViewTypeArea] = useState<DateView>('day');
  const [viewType, setViewType] = useState<DateView>('month');
  const handleDateChange = (date: string, value: 'bar' | 'area') => {
    if (value === 'bar') {
      setBarDate(date);
      generateBarData();
      return;
    } else {
      setDate(date);
      generateData();
      return;
    }
  };

  // TODO: remove this after BE finishes
  const generateData = () => {
    let data: { value: number; time: string }[] = [];
    for (let i = 0; i < 22; i++) {
      data.push({
        value: Math.floor(Math.random() * 10000),
        time: dayjs().hour(i).format('HH') + 'h'
      });
    }
    return data;
  };
  const generateBarData = () => {
    let barData: BarData[] = [];
    let startDate = dayjs(barDate).startOf('month').format();

    for (let i = 0; i < endDate; i++) {
      barData.push({
        timestampStartOfPeriod: dayjs(startDate)
          .add(i == 0 ? 0 : i, 'day')
          .format('DD'),
        cashPayment: Math.floor(Math.random() * 10000),
        cardPayment: Math.floor(Math.random() * 10000)
      });
    }
    return barData;
  };
  const generateYearData = () => {
    let barData: BarData[] = [];
    let startDate = dayjs(barDate).startOf('year').format();

    for (let i = 0; i < 12; i++) {
      barData.push({
        timestampStartOfPeriod: dayjs(startDate).add(i, 'month').format('MMM'),
        cashPayment: Math.floor(Math.random() * 10000),
        cardPayment: Math.floor(Math.random() * 10000)
      });
    }

    return barData;
  };

  return (
    <Box m={2}>
      <Grid container spacing={3} mb={1}>
        <Grid item xs={12} sm={4}>
          <AnalyticsCard
            header=""
            content={`${Math.floor(Math.random() * 20000000).toLocaleString()} RSD`}
            percent={`${(Math.random() * 10).toFixed(2)}`}
            footerText={t('finances_overview.total_income_for_selected_object')}
            headerIcon={<HouseIcon fill="white" />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <AnalyticsCard
            header=""
            content={`${Math.floor(Math.random() * 15000000).toLocaleString()} RSD`}
            percent={`${(Math.random() * 10).toFixed(2)}`}
            footerText={t('finances_overview.total_income_through_the_app')}
            headerIcon={<MobileIcon fill="white" />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <AnalyticsCard
            header=""
            content={`${Math.floor(Math.random() * 1500000).toLocaleString()} RSD`}
            percent={`${(Math.random() * 10).toFixed(2)}`}
            footerText={t('finances_overview.total_income_in_the_future')}
            headerIcon={<ClockIcon fill="white" />}
          />
        </Grid>
        <Grid item xs={12}>
          <CardWrapper
            sx={{ m: 0 }}
            title={
              <Stack>
                <Typography variant="h5">{t('income')}</Typography>
                <Typography variant="h3">
                  {Math.floor(Math.random() * 1500000).toLocaleString()} RSD
                </Typography>
              </Stack>
            }
            subtitle={
              t('finances_overview.total_income') + ' ' + t('finances_overview.for_selected_period')
            }
            showMore={
              <FinanceCardNavigation
                viewType={viewTypeArea}
                value={dayjs().format('DD. MMM YYYY')}
                onChange={date => handleDateChange(date, 'area')}
                maxDate={dayjs().format('DD. MMM YYYY')}
                withChangeView
                setViewType={setViewTypeArea}
              />
            }
            children={
              <>
                <Divider />
                {viewTypeArea === 'day' ? (
                  <AreaChartSM data={generateData()} tooltipValueText={t('income').toString()} />
                ) : (
                  <BarChartCustom
                    data={viewTypeArea === 'month' ? generateBarData() : generateYearData()}
                    yKey={'timestampStartOfPeriod'}
                    stackId="a"
                  />
                )}
              </>
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={1}>
        <Grid item xs={12} sm={4}>
          <CardWrapper
            sx={{ m: 0 }}
            title={
              <Stack>
                <Typography variant="h5">{t('income')}</Typography>
                <Typography variant="h3">
                  {Math.floor(Math.random() * 1500000).toLocaleString()} RSD
                </Typography>
              </Stack>
            }
            subtitle={
              t('finances_overview.total_income') + ' ' + t('finances_overview.for_selected_period')
            }
            showMore={<></>}
            children={
              <>
                <Divider />
                <Box sx={{ height: 270 }}>
                  <PieChartSM
                    data={[
                      { name: t('sidebar.app'), value: Math.floor(Math.random() * 10000) },
                      {
                        name: t('sidebar.regulars'),
                        value: Math.floor(Math.random() * 10000)
                      },
                      { name: t('coaches'), value: Math.floor(Math.random() * 10000) }
                    ]}
                    dataKey={'value'}
                  />
                </Box>
              </>
            }
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <CardWrapper
            sx={{ m: 0 }}
            title={
              <Stack>
                <Typography variant="h5">{t('income')}</Typography>
                <Typography variant="h3">
                  {Math.floor(Math.random() * 1500000).toLocaleString()} RSD
                </Typography>
              </Stack>
            }
            subtitle={
              t('finances_overview.total_income') + ' ' + t('finances_overview.for_selected_period')
            }
            showMore={
              <FinanceCardNavigation
                viewType={viewType}
                value={dayjs().format('DD. MMM YYYY')}
                onChange={date => handleDateChange(date, 'bar')}
                maxDate={dayjs().format('DD. MMM YYYY')}
                withChangeView
                setViewType={setViewType}
              />
            }
            children={
              <>
                <Divider />
                {viewType === 'day' ? (
                  <AreaChartSM data={generateData()} tooltipValueText={t('income').toString()} />
                ) : (
                  <BarChartCustom
                    data={viewTypeArea === 'month' ? generateBarData() : generateYearData()}
                    yKey={'timestampStartOfPeriod'}
                    stackId="a"
                  />
                )}
              </>
            }
          />
        </Grid>
      </Grid>

      <CardWrapper
        sx={{ m: 0 }}
        title={t('finances_overview.last_transactions').toString()}
        showMore={
          <Button variant="outlined" endIcon={<ArrowRightIcon />}>
            {t('view_all')}
          </Button>
        }
        children={
          <>
            <TableHeaderActions tableVariant="objects" hideActionBtn handleSearch={() => {}} />
            <Grid item lg={12} ml={'20px'} mr={'20px'}>
              <TableLayout
                tableVariant="adminUsers"
                rows={[]}
                columns={[]}
                pagination={{
                  pageIndex: 1,
                  pageSize: 10
                }}
              />
            </Grid>
          </>
        }
      />
      <CardWrapper
        sx={{ m: 0 }}
        title={t('admin_analytics.most_active_users').toString()}
        showMore={
          <Button variant="outlined" endIcon={<ArrowRightIcon />}>
            {t('view_all')}
          </Button>
        }
        children={
          <>
            <TableHeaderActions tableVariant="objects" hideActionBtn handleSearch={() => {}} />
            <Grid item lg={12} ml={'20px'} mr={'20px'}>
              <TableLayout
                tableVariant="adminUsers"
                rows={[]}
                columns={[]}
                pagination={{
                  pageIndex: 1,
                  pageSize: 10
                }}
              />
            </Grid>
          </>
        }
      />
    </Box>
  );
};

export default AdminFinancePage;
