//hooks
import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
//components
import { TabLink } from '@components/TabLink/TabLink';
import { TabButton } from '@components/TabButton/TabButton';
//icons
import {
  AnalyticsIcon,
  CalendarIcon,
  DashboardIcon,
  EmployeesIcon,
  FinanceIcon,
  LeagueIcon,
  NotificationIcon,
  SettingsIcon,
  SportsCentersIcon,
  TournamentIcon,
  UsersIcon
} from '@src/ui/components/icons/icons';
//interface
import { TabEnum } from '@src/domain/enum/tabEnum';
import { RoleEnum } from '@enum/roleEnum';
//atom
import { useRecoilValue } from 'recoil';
import { currentCMSAtom } from '@atoms/currentCMS';
import { PermissionsEnum } from '@enum/permissionsEnum';
//theme
import theme from '@src/theme';

type TabLinkType = {
  [key: string]: { [key: string]: ReactNode };
};

const links: TabEnum[] = [TabEnum.Users, TabEnum.Analytics, TabEnum.Finance, TabEnum.Cooperation];

const getArrayTabLinks = (
  translation: (key: string) => string,
  sportCenterId: undefined | string
): TabLinkType => ({
  superadmin_users: {
    read_users: (
      <TabLink
        text={translation('sidebar.allUsers')}
        key={translation('sidebar.allUsers')}
        link="/users"
      />
    ),
    read_appUsers: (
      <TabLink
        text={translation('sidebar.appUsers')}
        key={translation('sidebar.appUsers')}
        link="/users/app-users"
      />
    ),
    read_ownerUsers: (
      <TabLink
        text={translation('sidebar.owners')}
        key={translation('sidebar.owners')}
        link="/users/owners"
      />
    ),
    read_coach: (
      <TabLink
        text={translation('sidebar.superusers')}
        key={translation('sidebar.superusers')}
        link="/users/superusers"
      />
    ),

    read_bussinessUsers: (
      <TabLink
        text={translation('sidebar.businessUsers')}
        key={translation('sidebar.businessUsers')}
        link="/users/business"
        coming_soon={translation('soon')}
      />
    )
  },
  superadmin_cooperations: {
    read_employees: (
      <TabLink
        text={translation('sidebar.employees')}
        key={translation('sidebar.employees')}
        link="/cooperation/employees"
      />
    ),
    read_externalEmployees: (
      <TabLink
        text={translation('sidebar.externalEmployees')}
        key={translation('sidebar.externalEmployees')}
        link="/cooperation/external-employees"
        coming_soon={translation('soon')}
      />
    ),
    read_freelanceCooperations: (
      <TabLink
        text={translation('sidebar.partTimeEmployees')}
        key={translation('sidebar.partTimeEmployees')}
        link="/cooperation/part-time-employees"
        coming_soon={translation('soon')}
      />
    ),
    read_sponsorshipCooperations: (
      <TabLink
        text={translation('sidebar.sponsorship')}
        key={translation('sidebar.sponsorship')}
        link="/cooperation/sponsorships"
        coming_soon={translation('soon')}
      />
    )
  },
  superadmin_analytics: {
    read_overviewAnalytics: (
      <TabLink
        text={translation('sidebar.analyticsOverview')}
        key={translation('sidebar.analyticsOverview')}
        link="/analytics"
        // coming_soon={translation('soon')}
      />
    ),
    read_sportCenterAnalytics: (
      <TabLink
        text={translation('sidebar.analyticsObjects')}
        key={translation('sidebar.analyticsObjects')}
        link="/analytics/object"
        // coming_soon={translation('soon')}
      />
    ),
    read_userAnalytics: (
      <TabLink
        text={translation('sidebar.analyticsUsers')}
        key={translation('sidebar.analyticsUsers')}
        link="/analytics/users"
        // coming_soon={translation('soon')}
      />
    )
  },
  superadmin_finance: {
    read_overviewFinance: (
      <TabLink
        text={translation('sidebar.financeOverview')}
        key={translation('sidebar.financeOverview')}
        link="/finance"
        coming_soon={translation('soon')}
      />
    ),
    read_expenseFinance: (
      <TabLink
        text={translation('sidebar.expenses')}
        key={translation('sidebar.expenses')}
        link="/finance/expenses"
        coming_soon={translation('soon').toString()}
      />
    ),
    read_incomeFinance: (
      <TabLink
        text={translation('sidebar.income')}
        key={translation('sidebar.income')}
        link="/finance/income"
        coming_soon={translation('soon')}
      />
    ),
    read_reportFinance: (
      <TabLink
        text={translation('sidebar.reports')}
        key={translation('sidebar.reports')}
        link="/finance/reports"
        coming_soon={translation('soon')}
      />
    ),
    read_invoiceFinance: (
      <TabLink
        text={translation('sidebar.invoices')}
        key={translation('sidebar.invoices')}
        link="/finance/invoices"
        coming_soon={translation('soon')}
      />
    )
  },
  admin_users: {
    read_users: (
      <TabLink
        text={translation('sidebar.users')}
        link={`/users/${sportCenterId}`}
        key={translation('sidebar.users')}
        pathname={window.location.href}
        secondPathname={true}
      />
    ),
    read_recurring_appointments: (
      <TabLink
        text={translation('sidebar.regulars')}
        link={`/users/${sportCenterId}/regulars`}
        key={translation('sidebar.regulars')}
        pathname={window.location.href}
      />
    ),
    read_coaches: (
      <TabLink
        text={translation('sidebar.coaches')}
        link={`/users/${sportCenterId}/coaches`}
        key={translation('sidebar.coaches')}
        pathname={window.location.href}
      />
    ),
    read_employees: (
      <TabLink
        text={translation('sidebar.employees')}
        link={`/employees/${sportCenterId}`}
        key={translation('sidebar.employees')}
        pathname={window.location.href}
      />
    )
  },
  admin_finance: {
    read_finance: (
      <TabLink
        text={translation('sidebar.financeOverview')}
        link={`/finance/${sportCenterId}`}
        key={translation('sidebar.financeOverview')}
        pathname={window.location.href}
        coming_soon={translation('soon')}
      />
    )
  }
});

export function useSidemenu() {
  const [active, setActive] = useState(TabEnum.None);
  const { t: translation } = useTranslation();
  const link = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const currentCMS = useRecoilValue(currentCMSAtom);
  useEffect(() => {
    links.find(el => {
      if (link.pathname.split('/')[1] === el) {
        setActive(el);
      }
    });
  }, [link]);

  const getTabs = (key: keyof TabLinkType) => {
    const tabLinks = getArrayTabLinks(translation, currentCMS?.currentSportCenter);
    return Object.keys(tabLinks[key])
      .map(tab => {
        if (currentCMS?.role === RoleEnum.SUPERADMIN || currentCMS?.role === RoleEnum.ADMIN) {
          return tabLinks[key][tab];
        }
        if (currentCMS?.permissions?.includes(tab)) {
          return tabLinks[key][tab];
        }
      })
      .filter(el => el !== undefined);
  };

  const sidemenu: {
    role: RoleEnum[];
    element: React.ReactNode;
    permission: string;
  }[] = [
    {
      role: [RoleEnum.SUPERADMIN, RoleEnum.SUPERADMIN_EMPLOYEE],
      permission: '', //PermissionsEnum.read_dashboard,
      element: !isMobile && (
        <TabLink
          key={translation('sidebar.dashboard')}
          text={translation('sidebar.dashboard')}
          link="/dashboard"
          icon={<DashboardIcon />}
          coming_soon={translation('soon').toString()}
        />
      )
    },
    {
      role: [RoleEnum.SUPERADMIN, RoleEnum.SUPERADMIN_EMPLOYEE],
      permission: PermissionsEnum.read_sport_centers,
      element: (
        <TabLink
          key={translation('sidebar.objects')}
          text={translation('sidebar.objects')}
          link="/objects"
          icon={<SportsCentersIcon />}
          pathname={link.pathname}
        />
      )
    },
    {
      role: [RoleEnum.SUPERADMIN, RoleEnum.SUPERADMIN_EMPLOYEE],
      permission: PermissionsEnum.read_users,
      element: !isMobile ? (
        <TabButton
          key={'all-users-button'}
          text={translation('sidebar.users')}
          index={TabEnum.Users}
          down={active === TabEnum.Users}
          icon={<UsersIcon />}
          onClick={setActive}
          dataTestId={'users'}
        >
          <TabLink
            text={translation('sidebar.allUsers')}
            key={translation('sidebar.allUsers')}
            link="/users"
          />

          <TabLink
            text={translation('sidebar.appUsers')}
            key={translation('sidebar.appUsers')}
            link="/users/app-users"
            pathname={link.pathname}
          />

          <TabLink
            text={translation('sidebar.owners')}
            key={translation('sidebar.owners')}
            link="/users/owners"
          />

          <TabLink
            text={translation('sidebar.superusers')}
            key={translation('sidebar.superusers')}
            link="/users/superusers"
            pathname={link.pathname}
          />

          <TabLink
            text={translation('sidebar.businessUsers')}
            key={translation('sidebar.businessUsers')}
            link="/users/business"
            coming_soon={translation('soon').toString()}
          />
        </TabButton>
      ) : (
        <TabLink
          text={translation('sidebar.users')}
          key={translation('sidebar.allUsers')}
          link="/users"
          icon={<UsersIcon />}
        />
      )
    },
    {
      role: [RoleEnum.SUPERADMIN, RoleEnum.SUPERADMIN_EMPLOYEE],
      permission: '',
      element: !isMobile && getTabs('superadmin_cooperations').length > 0 && (
        <TabButton
          key={translation('sidebar.employees')}
          text={translation('sidebar.employees')}
          down={active === TabEnum.Cooperation}
          index={TabEnum.Cooperation}
          icon={<EmployeesIcon />}
          onClick={setActive}
          dataTestId={'employees'}
        >
          {getTabs('superadmin_cooperations')}
        </TabButton>
      )
    },
    {
      role: [RoleEnum.SUPERADMIN, RoleEnum.SUPERADMIN_EMPLOYEE],
      permission: '',
      element: getTabs('superadmin_analytics').length > 0 && (
        <TabButton
          key={translation('sidebar.analytics')}
          text={translation('sidebar.analytics')}
          down={active === TabEnum.Analytics}
          index={TabEnum.Analytics}
          icon={<AnalyticsIcon />}
          onClick={setActive}
          dataTestId={'analytics'}
          // coming_soon={translation('soon').toString()}
        >
          {getTabs('superadmin_analytics')}
        </TabButton>
      )
    },
    {
      role: [RoleEnum.SUPERADMIN, RoleEnum.SUPERADMIN_EMPLOYEE],
      permission: '',
      element: !isMobile && getTabs('superadmin_finance').length > 0 && (
        // <TabButton
        //   key={translation('sidebar.finance')}
        //   text={translation('sidebar.finance')}
        //   down={active === TabEnum.Finance}
        //   index={TabEnum.Finance}
        //   icon={<FinanceIcon />}
        //   onClick={setActive}
        //   dataTestId={'finance'}
        //   coming_soon={translation('soon').toString()}
        // >
        //   {getTabs('superadmin_finance')}
        // </TabButton>
        <TabLink
          key={translation('sidebar.finance')}
          text={translation('sidebar.finance')}
          link="/finance"
          icon={<FinanceIcon />}
          coming_soon={translation('soon').toString()}
        />
      )
    },
    {
      role: [RoleEnum.SUPERADMIN, RoleEnum.SUPERADMIN_EMPLOYEE],
      permission: '',
      element: !isMobile && (
        <TabLink
          key={translation('sidebar.notifications')}
          text={translation('sidebar.notifications')}
          link="/notifications"
          icon={<NotificationIcon />}
          coming_soon={translation('soon').toString()}
        />
      )
    },
    {
      role: [RoleEnum.SUPERADMIN, RoleEnum.SUPERADMIN_EMPLOYEE],
      permission: '',
      element: (
        <TabLink
          key={translation('sidebar.settings')}
          text={translation('sidebar.settings')}
          link={`/settings`}
          icon={<SettingsIcon />}
          pathname={link.pathname}
        />
      )
    },
    {
      role: [RoleEnum.ADMIN, RoleEnum.ADMIN_EMPLOYEE, RoleEnum.COACH],
      permission: PermissionsEnum.read_appointments,
      element: (
        <TabLink
          key={translation('sidebar.calendar')}
          text={translation('sidebar.calendar')}
          link={`/calendar/${currentCMS?.currentSportCenter}`}
          icon={<CalendarIcon />}
        />
      )
    },

    {
      role: [RoleEnum.ADMIN, RoleEnum.ADMIN_EMPLOYEE],
      permission: '',
      element: !isMobile ? (
        <TabButton
          key={translation('sidebar.users')}
          text={translation('sidebar.users')}
          index={TabEnum.Users}
          down={active === TabEnum.Users}
          icon={<UsersIcon />}
          onClick={setActive}
          dataTestId={'users'}
        >
          {getTabs('admin_users')}
          <TabLink
            text={translation('sidebar.categories')}
            link={`/users/${currentCMS?.currentSportCenter}/categories`}
            coming_soon={translation('soon').toString()}
          />
        </TabButton>
      ) : (
        <TabLink
          key={'sidebar.users'}
          text={translation('sidebar.users')}
          link={`/users/${currentCMS?.currentSportCenter}`}
          icon={<UsersIcon />}
        />
      )
    },
    {
      role: [RoleEnum.ADMIN, RoleEnum.ADMIN_EMPLOYEE],
      permission: '',
      element: !isMobile && (
        // TODO: return this after MVP
        // <TabButton
        //   key={translation('sidebar.finance')}
        //   text={translation('sidebar.finance')}
        //   down={active === TabEnum.Finance}
        //   index={TabEnum.Finance}
        //   icon={<FinanceIcon />}
        //   onClick={setActive}
        //   dataTestId={'finance'}
        // >
        //   <TabLink
        //     text={translation('sidebar.financeOverview')}
        //     link={`/finance/${currentCMS?.currentSportCenter}`}
        //   />
        //   <TabLink
        //     text={translation('sidebar.app')}
        //     link={`/finance/${currentCMS?.currentSportCenter}/app`}
        //     coming_soon={translation('soon').toString()}
        //   />
        //   <TabLink
        //     text={translation('sidebar.regulars')}
        //     link={`/finance/${currentCMS?.currentSportCenter}/regulars`}
        //     coming_soon={translation('soon').toString()}
        //   />
        //   <TabLink
        //     text={translation('sidebar.superusers')}
        //     link={`/finance/${currentCMS?.currentSportCenter}/superusers`}
        //     coming_soon={translation('soon').toString()}
        //   />
        //   <TabLink
        //     text={translation('sidebar.categories')}
        //     link={`/finance/${currentCMS?.currentSportCenter}/categories`}
        //     coming_soon={translation('soon').toString()}
        //   />
        // </TabButton>
        <TabLink
          key={translation('sidebar.finance')}
          text={translation('sidebar.finance')}
          link={`/finance/${currentCMS?.currentSportCenter}`}
          icon={<FinanceIcon />}
          coming_soon={translation('soon').toString()}
        />
      )
    },
    {
      role: [RoleEnum.ADMIN, RoleEnum.ADMIN_EMPLOYEE],
      permission: '',
      element: (
        <TabLink
          key={translation('sidebar.analytics')}
          text={translation('sidebar.analytics')}
          link={`/analytics/${currentCMS?.currentSportCenter}`}
          icon={<AnalyticsIcon />}
          // coming_soon={translation('soon').toString()}
        />
      )
    },
    {
      role: [RoleEnum.ADMIN, RoleEnum.ADMIN_EMPLOYEE],
      permission: '',
      element: !isMobile && (
        <TabLink
          key={translation('sidebar.tournaments')}
          text={translation('sidebar.tournaments')}
          link={`/tournaments/${currentCMS?.currentSportCenter}`}
          icon={<TournamentIcon />}
          coming_soon={translation('soon').toString()}
        />
      )
    },

    {
      role: [RoleEnum.ADMIN, RoleEnum.ADMIN_EMPLOYEE],
      permission: '',
      element: !isMobile && (
        <TabLink
          key={translation('sidebar.leagues')}
          text={translation('sidebar.leagues')}
          link={`/leagues/${currentCMS?.currentSportCenter}`}
          icon={<LeagueIcon />}
          coming_soon={translation('soon').toString()}
        />
      )
    },

    {
      role: [RoleEnum.ADMIN, RoleEnum.ADMIN_EMPLOYEE, RoleEnum.COACH],
      permission: '',
      element: (
        <TabLink
          key={translation('sidebar.settings')}
          text={translation('sidebar.settings')}
          link={`/settings/${currentCMS?.currentSportCenter}`}
          icon={<SettingsIcon />}
          pathname={link.pathname}
        />
      )
    }
  ];

  function getSidemenu(): ReactNode[] {
    const role = currentCMS?.role;
    const permissions = currentCMS?.permissions;

    return sidemenu.map(el => {
      if (el.role.find(r => r === role) !== undefined) {
        if (role === RoleEnum.SUPERADMIN || role === RoleEnum.ADMIN || role === RoleEnum.COACH)
          return el.element;
        else {
          if (el.permission === '') {
            return el.element;
          }
          if (permissions?.find(p => p === el.permission) !== undefined) {
            return el.element;
          } else return null;
        }
      }
    });
  }

  return { getSidemenu };
}
